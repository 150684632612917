import React from 'react';
import './index.css';
import $ from "jquery";

let prevIndex = 1;
let total = 0;

$('#testimonials .testimonial').css({'transform': 'translateY(100%)', 'position': 'absolute', 'opacity': 0});
$('#testimonials .q1').css({'transform': 'translateY(0%)', 'position': 'relative', 'opacity': 1});

function showQuote( e, index ) {
    $('#testimonials .quote .right .circle').removeClass('active');
    e.target.classList.add('active');
    if(prevIndex > index){
        for(let i = index; i < prevIndex + 1; i++ ){
            $('#testimonials .q' + i).css({'transform': 'translateY(100%)', 'position': 'absolute', 'opacity': 0});
            $('#testimonials .q' + index).css({'transform': 'translateY(0%)', 'position': 'relative', 'opacity': 1});
        }
    }else{
        for(let i = prevIndex; i < index + 1; i++ ){
            $('#testimonials .q' + i).css({'transform': 'translateY(-100%)', 'position': 'absolute', 'opacity': 0});
            $('#testimonials .q' + index).css({'transform': 'translateY(0%)', 'position': 'relative', 'opacity': 1});
        }
    }

    prevIndex = index;
}

function animateTestimonial() {
    if($(document.querySelector('#testimonials .box')).data('hover')?true:false){
        let index;
        if(prevIndex === total){
            index = 1;
        }else{
            index = prevIndex + 1;
        }

        $('#testimonials .quote .right .circle').removeClass('active');
        $('#testimonials .box .quote .right .circle:nth-of-type(' + index + ')').addClass('active');
        $('#testimonials .testimonial').css({'transform': 'translateY(100%)', 'position': 'absolute', 'opacity': 0});
        if(prevIndex > index){
            for(let i = index; i < prevIndex + 1; i++ ){
                $('#testimonials .q' + i).css({'transform': 'translateY(100%)', 'position': 'absolute', 'opacity': 0});
                $('#testimonials .q' + index).css({'transform': 'translateY(0%)', 'position': 'relative', 'opacity': 1});
            }
        }else{
            for(let i = prevIndex; i < index + 1; i++ ){
                $('#testimonials .q' + i).css({'transform': 'translateY(-100%)', 'position': 'absolute', 'opacity': 0});
                $('#testimonials .q' + index).css({'transform': 'translateY(0%)', 'position': 'relative', 'opacity': 1});
            }
        }

        prevIndex = index;
    }
}

setInterval(animateTestimonial, 5000);

function Testimonials( { items } ) {

    // indexing items ==============
    let index = 1;

    for(let i in items){
        items[i].index = index;
        index++;
    }
    // =============================

    total = index - 1;

    return (
        <div className="section" id="testimonials">
            <h4>What our clients say</h4>
            <div className="content">
                <div className="box">
                        <div className="quote">
                            {
                                items.map(item => {
                                    return (
                                        <div className={ "q" + item.index + " left testimonial" }>
                                            <p>{ item.quote }</p>
                                            <div className="footer">
                                                <img src="" alt=""/>
                                                <h4>{ item.name }</h4>
                                                <h5>{ item.title }</h5>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="right">
                                {
                                    items.map(item => {
                                        if(item.index === 1){
                                            return (
                                                <div className="active circle" onClick={ (e) => { showQuote(e, item.index); } }></div>
                                            )
                                        }else{
                                            return (
                                                <div className="circle" onClick={ (e) => { showQuote(e, item.index); } }></div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
           </div>
        </div>
    );
}

export default Testimonials;