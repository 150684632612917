import React from 'react';
import './index.css';

function OdooServices(){
    return (
        <>
            <div className="section" id="odooserviceshead">
                <div className="container">
                    <h1>Odoo Services</h1>
                    <p>Between the Odoo apps and the tens of thousands of Community apps, there is something to help address all of your business needs in a single, cost-effective and modular solution: no more work to get different technology cooperating.</p>
                    <button className="btn btn-primary">Contact us now</button>
                </div>
            </div>
            <div className="section odooservices implementation">
                <div className="container">
                    <div className="box">
                        <h3>Full implementation</h3>
                        <p>We in-house talented and skilled Odoo developers to build your Odoo modules and customize your Odoo. Our extended team of IT experts makes sure every Odoo modules are built the right way and our customers gain 100% satisfaction from our service. The Odoo ERP implementation company takes pride in serving 160+ countries and many versatile Odoo projects.</p>
                        <p>One single application for all your business need. Odoo encompasses different modules that precisely answer your business requirements.</p>
                    </div>
                    <div className="box image"></div>
                </div>
            </div>
            <div className="cover">
                <div className="section odooservices configuration">
                    <div className="container"> 
                        <div className="box image desktop"></div>
                        <div className="box">
                            <h3>Just configuration</h3>
                            <p>With that amount of user concurrency you need to have as much as ram and cpu cores that you could get, let's said that you have 32gb, and 10 cpu cores, you need to config Odoo to use this resources as best as it could using workers that enables multiprocesing.</p>
                            <p>The database server is in the same server or in another? if it's in the same server you need to separate an amount of ram out of the Odoo resources calculations for setting PosgreSQL database memory tunnings. Also I recomends a pool strategy in front of the database server like pg_bouncer that will protect your PosgreSQL instance from get out of connection slots.</p>
                        </div>
                        <div className="box image mobile"></div>
                    </div>
                </div>
            </div>
            <div className="section odooservices module">
                <div className="container"> 
                    <div className="box">
                        <h3>Custom module development</h3>
                        <p>Odoo modules can either add brand new business logic to an Odoo system, or alter and extend existing business logic: a module can be created to add your country’s accounting rules to Odoo’s generic accounting support, while the next module adds support for real-time visualisation of a bus fleet.</p>
                        <p>A new modul created to be customized based of my needs. So I can completely created new one without depend on existing module. Or I can take advantage of existing modules by inherit them to modify it. And how to create this is so easy.</p>
                    </div>
                    <div className="box image"></div>
                </div>
            </div>
            <div className="cover">
                <div className="section odooservices migration">
                    <div className="container"> 
                        <div className="box image desktop"></div>
                        <div className="box">
                            <h3>Migration</h3>
                            <p>Undeniably, the latest versions of Odoo include improved features such as security, speed, and stability. Migrating to the latest version indicates that you are ready to update your system and move forward. Moving forward does not mean that you lose your old data and turn to new data. Migration ensures that all the previous data is kept intact while stepping into the new one.</p>
                            <p>The latest version, Odoo 14 got released in October 2020. As businesses are ever-changing, the new version comes with many added or deleted features as found optimal by Odoo.</p>
                        </div>
                        <div className="box image mobile"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OdooServices;