import React from 'react';
import './index.css';
import {FaCloud, FaHandHoldingUsd} from 'react-icons/fa';
import {MdSecurity} from 'react-icons/md';
import {GiExpand} from 'react-icons/gi';

const iconStyles = { color: '#952a90', fontSize: '1.5rem'};

function Speciality(){
    return (
        <div className="section" id="speciality">
            <div className="box">
                <div className="icon"><FaCloud style={ iconStyles }/></div>
                <h3>Cloud Based</h3>
                <p>You don't need to store any data on the hard disk of your personal computer.</p>
            </div>
            <div className="box">
                <div className="icon"><MdSecurity style={ iconStyles }/></div>
                <h3>Secured</h3>
                <p>We provide well-designed solutions that can offer greater levels of security.</p>
            </div>
            <div className="box">
                <div className="icon"><FaHandHoldingUsd style={ iconStyles }/></div>
                <h3>Cost Effective</h3>
                <p>Not loaded with features don’t need and without licensing fee to save money</p>
            </div>
            <div className="box">
                <div className="icon"><GiExpand style={ iconStyles }/></div>
                <h3>Scalable</h3>
                <p>We aim to accommodate all such expansion in capability like IoT software</p>
            </div>
        </div>
    )
}

export default Speciality;