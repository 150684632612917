import React, { useState, useEffect } from 'react';
import './index.css';
import { MdArrowBack } from 'react-icons/md';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from '../../config/firebase.config';

function BlogPost(){
    const params = useParams()

    const [post, setPost] = useState({});
    useEffect(() => {
            
        const docRef = doc(db, "posts", params.id);
        const getPost = async () => {
            await getDoc(docRef).then(docSnap => {

                if (docSnap.exists()) {
                    setPost(docSnap.data());
                    updateDoc(docRef, { view: parseInt(docSnap.data().view) + 1 });
                }
            });
        };
        getPost();
    }, []);

    return (
        <div className="section" id="blog-post">
            <div className='go-back'>
                <Link to="/blog"><button><MdArrowBack/></button></Link>
            </div>
            
            <h1>{post.title}</h1>
            <div className='image' style={{backgroundImage: 'url(' + post.image + ')'}}></div>
            <div className='date'>{ post.date == undefined ? post.date : post.date.toDate().toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"}) }</div>
            <div className='content' dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </div>
    )
}

export default BlogPost;