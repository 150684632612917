import React from 'react';
import './index.css';

function HomeSec(){
    return (
        <div className="section" id="home">
            <div className="left">
                <h4>Accelerate your business growth with our custom made <a href='#home' disabled>solutions</a></h4>
                <p>Our Odoo ERP development company has years of experience in customizing and implementing the Odoo solutions
                for our clients and fulfilling their specific business prerequisites.</p>
                <a href="/about-odoo"><button className="btn btn-primary">Learn More</button></a>
            </div>
            <div className="right">
                <div className="first boxes">
                    <div className="box"></div>
                    <div className="box"></div>
                </div>
                <div className="second boxes">
                    <div className="box"></div>
                    <div className="box">
                        <div className="in">
                            <p>Our goal: <br/><br/>
                            <span>“We create technology for a sustainable future.”</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSec;