import React from "react";
import './index.css';
import $ from "jquery";

function onSubmitForm(e) {
    e.preventDefault();

    window.emailjs.send("service_ly07hwq","template_e1wyzkx",{
        name: $(e.target).find('#name').val(),
        email: $(e.target).find('#email').val(),
        mobile: $(e.target).find('#phone').val(),
        subject: $(e.target).find('#subject').val(),
        message: $(e.target).find('#message').val()
    }).then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        e.target.reset();
        $('#contact-message h5').css({'display':'block'});
    }, function(error) {
        console.log('FAILED...', error);
    });
}

function Message() {
    return (
        <div id="contact-message">
            <div className="section">
                <h3>Tell us about yourself</h3>
                <p>Please fill out the form and we will be in touch with lightening speed.</p>

                <form action="" onSubmit={ onSubmitForm }>

                    <div className="input-group">
                        <label htmlFor="#name">Your name</label>
                        <input type="text" name="name" id="name" placeholder="Name, surname"/>
                    </div>

                    <div className="input-group">
                        <label htmlFor="#email">Your email address</label>
                        <input type="email" name="email" id="email" placeholder="Email"/>
                    </div>

                    <div className="input-group">
                        <label htmlFor="#subject">Subject</label>
                        <input type="text" name="subject" id="subject" placeholder="Enter subject"/>
                    </div>

                    <div className="input-group">
                        <label htmlFor="#phone">Your phone number</label>
                        <input type="text" name="phone" id="phone" placeholder="Phone"/>
                    </div>

                    <div className="input-group">
                        <label htmlFor="#message">How can we help you?</label>
                        <textarea name="message" id="message" placeholder="Your message" rows="6"></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>

                </form>

                <h5>We have recieved your enquiry. We'll get back to you soon.</h5>
            </div>
        </div>
    )
}

export default Message;