import React from 'react';
import './index.css';
import Image from '../../img/about_2.jpg';

function AboutVision() {
    return (
        <div className="section" id="about-vision">
            <div className="right">
                <h3>Our Vision</h3>
                <p>Our vision is to bring on much more happy clients on board with a view of providing exquisite software solutions ranging from web development to mobile and ERP solutions. Our ERP solution will help you flourish your business with exception tools in use, leveraging to your business needs. </p>
                <p>We work closely with our clients ensuring that their requests are rightly taken into consideration and implemented well.</p>
            </div>
            <div className="left">
                <img src={ Image } alt=""/>
            </div>
        </div>
    )
}

export default AboutVision;