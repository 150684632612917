import React from 'react';
import './index.css';
import AboutOdooImg from '../../img/about_odoo.jpg';

function OdooIntro ()
{
    
    return (
        <div className="section" id="odoointro">
            <div className="left">
                <h3>Your all-in-one software solution</h3>
                <p>Odoo has become the favourite choice for many businesses because it handles a range of business needs. One cannot deny the importance of the features of Odoo as they are helping businesses to grow.</p>
                <p>Odoo is basically a comprehensive business management software. In other words, we can say that it is a complete suite of open source business apps.</p>
                {/* <a href="https://www.odoo.com/" target="_blank"><button className="btn btn-primary">Visit Website</button></a> */}
            </div>
            <div className="right">
                <img src={ AboutOdooImg } alt=""/>
            </div>
        </div>
    );

}

export default OdooIntro;