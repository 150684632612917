import React from 'react';
import './index.css';
import Image from '../../img/odoo_scr_1.png';
import {BsArrowRight} from 'react-icons/bs';

const iconStyles = {marginRight: '1rem'};

function Odoo(){
    return (
        <div className="section" id="odoo">
            <div className="left">
                <img src={ Image } alt=""/>
            </div>
            <div className="right">
                <h4>All in one Odoo solution</h4>
                <p>Odoo’s complete suite of tools and all-in-one management software can transform the way you do business with its unique modular technology.</p>
                <ul>
                    <li><BsArrowRight style={ iconStyles }/> <span>Flexible and customizable</span> </li>
                    <li><BsArrowRight style={ iconStyles }/> <span>User friendly software</span> </li>
                    <li><BsArrowRight style={ iconStyles }/> <span>Custom Readymade Apps</span> </li>
                    <li><BsArrowRight style={ iconStyles }/> <span>Budget Friendly</span> </li>
                </ul>
            </div>
        </div>
    )
}

export default Odoo;