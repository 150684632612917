import React from 'react';
import './index.css';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Top({ title, nav }){
    let count = nav.length -1;
    return (
        <div id="about-top">
            <div className="section">
                <h3>{ title }</h3>
                <h6>
                    {
                        nav.map(item => {
                            if(count > 0){
                                count--;
                                return (
                                    <><Link to={ item.link }>{ item.name }</Link><FaAngleRight /></>
                                )
                            }else{
                                return (
                                    <Link to={ item.link }>{ item.name }</Link>
                                )
                            }
                        })
                    }
                </h6>
            </div>
        </div>
    )
}

export default Top;