import React from 'react';
import './index.css';
import img from '../../img/graphic_design.png'
import { SiAdobeillustrator, SiAdobephotoshop, SiAdobeindesign, SiInkscape, SiGimp, SiKrita } from 'react-icons/si';

function GraphicDesign(){
    return (
        <>
            <div className="section" id="grahichead">
                <div className="container">
                    <img src={img} className="box image" alt='graphic designing'/>
                    <div className="box">
                        <h1>Graphic Designing Service</h1>
                        <p>There's no limit to what you can get designed at Amzsys. Whether you're looking for a spectacular new logo or some stunning flyers, the talented global community of designers at Amzsys can make it happen. Amzsys prides itself on it's high quality designers who deliver only the best graphic design services. Find the right design service for you below and get design you'll love today!</p>
                        <p>Need to make a lasting impression? The right combination of colors, shapes and words will define your brand. Get the perfect design now.</p>
                        {/* <button className="btn btn-primary">Contact us now</button> */}
                    </div>
                </div>
            </div>

            <div className="section graphics-stripe">
                <div className="box">
                    <h3>We are updated with the latest graphic designing technologies and our designers are well experienced to deliver the fastest and valuable services.</h3>
                </div>
                <div className="box">
                    <div className="box image"></div>
                    <dib className="box">
                        <p>These tools are tried and tested and can help us create spectacular custom designs that will leave our clients spellbound</p>
                    </dib>
                </div>
            </div>

            <div className="section tech">
                <div className="grid">
                    <div className="box">
                        <div className="icon">
                            <SiAdobeillustrator />
                        </div>
                        <div className="content">
                            <h3>Adobe Illustrator</h3>
                            <p>Adobe Illustrator is a vector graphics editor and design program developed and marketed by Adobe Inc</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <SiAdobephotoshop />
                        </div>
                        <div className="content">
                            <h3>Adobe Photoshop</h3>
                            <p>Adobe Photoshop is a raster graphics editor developed and published by Adobe Inc. for Windows and macOS</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <SiAdobeindesign />
                        </div>
                        <div className="content">
                            <h3>Adobe Indesign</h3>
                            <p>Adobe InDesign is a desktop publishing and page layout designing software application produced by Adobe Inc</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <SiGimp />
                        </div>
                        <div className="content">
                            <h3>Gimp</h3>
                            <p>GIMP is a cross-platform image editor available for GNU/Linux, macOS, Windows and more operating systems</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <SiInkscape />
                        </div>
                        <div className="content">
                            <h3>Inkscape</h3>
                            <p>Inkscape is a free and open-source vector graphics editor used to create vector images, primarily in Scalable Vector Graphics format</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <SiKrita />
                        </div>
                        <div className="content">
                            <h3>Krita</h3>
                            <p>Krita is a professional FREE and open source painting program. It is made by artists that want to see affordable art tools for everyone</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GraphicDesign;