import React from 'react';
import './index.css';
import img from '../../img/coming_soon.png'

function ComingSoon(){
    return (
        <>
            <div className="section" id="comingsoon">
                <div className="container">
                    <img src={img} alt="" />
                    <h1>Coming Soon</h1>
                    <p>We are currently working on this page. Please keep checking back.</p>
                    <button className="btn btn-primary">Back to home</button>
                </div>
            </div>
        </>
    )
}

export default ComingSoon;