import React from 'react';
import './index.css';
import Image from '../../img/about_3.png';
import {FaCheck} from 'react-icons/fa';

function AboutSpeciality() {
    return (
        <div id="about-speciality">
            <div className="section">
                <div className="left">
                    <img src={ Image } alt=""/>
                </div>
                <div className="right">
                    <h3>We care delivery</h3>
                    <p>We care about our customers because they are the reason we are in business.</p>
                    <p>Our purpose is to create a brighter future together with our customers ranges from individuals, small businesses and mid-sized to large companies across the world.</p>
                    <ul>
                        <li><FaCheck />Yearly updates</li>
                        <li><FaCheck />Tech support</li>
                        <li><FaCheck />Compassion</li>
                        <li><FaCheck />Charisma</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AboutSpeciality;