import React from 'react';
import './index.css';
import img from '../../img/web_hosting.png'
import { AiOutlineMail } from 'react-icons/ai'
import { FiSmile } from 'react-icons/fi'
import { BiCheckShield, BiServer } from 'react-icons/bi'
import { RiFileTransferLine } from 'react-icons/ri'
import { GrDomain } from 'react-icons/gr'

function WebHosting(){
    return (
        <>
            <div className="section" id="grahichead">
                <div className="container">
                    <div className="box">
                        <h1>Web &amp; Email Hosting</h1>
                        <p>Experience reliable, high performance web hosting, combined with the all-powerful cPanel for amazingly easy site management, all backed by our exceptional 24x7 support. Recognised for being robust, adaptable and highly functional, Linux is a UNIX-based, Open-Source operating system, ideal for multiple users and multitasking.</p>
                        <p>Your data is in safe hands. We recognise how important your data is to you, which is why we back it up daily as part of our rigorous disaster recovery procedures.</p>
                        {/* <button className="btn btn-primary">Contact us now</button> */}
                    </div>
                    <img src={img} className="box image" alt='web hosting'/>
                </div>
            </div>

            <div className="section webhost-stripe">
                <div className="box">
                    <h3>Are you just getting started and want to represent your business with a customized email address and your own domain name?</h3>
                </div>
                <div className="box">
                    <div className="box image"></div>
                    <dib className="box">
                        <p>Our professional email service offer everything you need to boost your brand and to make it easier for your customers to remember you</p>
                    </dib>
                </div>
            </div>

            <div className="section tech">
                <div className="grid">
                    <div className="box">
                        <div className="icon">
                            <GrDomain />
                        </div>
                        <div className="content">
                            <h3>Custom domain email hosting</h3>
                            <p>Email hosting with us allows you to not only choose your own email name, but also your own domain name</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <RiFileTransferLine />
                        </div>
                        <div className="content">
                            <h3>Email migration</h3>
                            <p>For a smooth transition to your new email, you can arrange for all messages sent to your old address to be forwarded to your new business email address</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <BiCheckShield />
                        </div>
                        <div className="content">
                            <h3>Spam filter and virus protection</h3>
                            <p>Our email hosting plans come with an efficient spam filter and an additional virus scanner is available</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <FiSmile />
                        </div>
                        <div className="content">
                            <h3>Ad-free</h3>
                            <p>Unlike free email providers, which are financed by selling advertising space that crowds your user interface, email hosting plans are completely ad-free</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <AiOutlineMail />
                        </div>
                        <div className="content">
                            <h3>IMAP</h3>
                            <p>Email hosting portfolio includes full IMAP (Internet Message Access Protocol) support and webmail access so you can easily retrieve your email wherever you are</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon">
                            <BiServer />
                        </div>
                        <div className="content">
                            <h3>Web and email hosting</h3>
                            <p>Our email hosting service lets you create your own email domain, helping you to craft a professional appearance and stand out from the crowd</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WebHosting;