import React from 'react';
import './index.css';
import Quote from '../../img/quote_bg_3.svg';

function aboutWho(){
    return (
        <div id="about-who">
            <div className="section">
                <div className="left">
                    <h3>Who we are</h3>
                    <p>Amzsys evolved with a vision to provide one-stop solutions that cater the needs of a business and to help them soar high. Our mission is to provide a complete solution for all your business needs.</p>
                    <p>Our team of highly skilled employees is always available to help and support any kind of business. We pursue what we say, hence our stature among the existing clients are always at a higher level. Our team has highly determined professionals who provide smart work and adhere to the needs and requests of the client.</p>
                </div>

                <div className="right">
                    <img src={ Quote } alt=""/>
                    <div className="line"></div>
                    <h5>"We believe that it takes great people to deliver a great product"</h5>
                    <h6>Amzsys Team</h6>
                </div>
            </div>
        </div>
    );
}

export default aboutWho;