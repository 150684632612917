import React from 'react';
import './index.css';
import Image from '../../img/services.png';
import { FaAngleRight } from 'react-icons/fa';

function Services(){
    return (
        <div className="section" id="services">
            <div className="left">
                <h4>Solution for every business needs</h4>
                <p>We are expertise in various business domains allows us to create innovative, future-proof software solutions of any complexity.</p>
                <ul>
                    <li> <FaAngleRight /> Odoo ERP development</li>
                    <li> <FaAngleRight /> Web Development</li>
                    <li> <FaAngleRight /> Mobile Development</li>
                    <li> <FaAngleRight /> Application Development</li>
                    <li> <FaAngleRight /> API Development</li>
                    <li> <FaAngleRight /> Graphic designing</li>
                    <li> <FaAngleRight /> Digital Marketing</li>
                    <li> <FaAngleRight /> Web &amp; Email Hosting</li>
                </ul>
            </div>
            <div className="right">
                <img src={ Image } alt=""/>
            </div>
        </div>
    )
}

export default Services;