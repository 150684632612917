import React from 'react';
import './index.css';

function Contact(){
    return (
        <div className="section" id="contact">
            <h4>We design and develop web apps that delight your users and grow your business.</h4>
            <a href="/contact"><button className="btn btn-white">Contact us</button></a>
        </div>
    )
}

export default Contact;