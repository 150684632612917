import React, { useState, useEffect } from 'react';
import './index.css';
import db from '../../config/firebase.config';
import { Link } from "react-router-dom";
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import Loading from '../../img/loading.gif';

function Blog(){

    const [loaded, setLoaded] = useState(false);
    const [posts, setPosts] = useState([]);
    const [length, setLength] = useState(7);
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState("popular");
    const postsCollectionRef = collection(db, 'posts');
    const categoriesCollectionRef = collection(db, 'categories');
    useEffect(() => {
        const getPosts = async () => {
            var q = query(postsCollectionRef, orderBy("view", "desc"), limit(length));
            if (filter == "recent"){
                q = query(postsCollectionRef, orderBy("date", "desc"), limit(length));
            }else if (filter == "popular"){
                q = query(postsCollectionRef, orderBy("view", "desc"), limit(length));
            }else{
                q = query(postsCollectionRef, where("category", "==", filter), orderBy("date", "desc"), limit(length));
            }
            const data = await getDocs(q);
            const data2 = await getDocs(categoriesCollectionRef);
            setPosts(data.docs.map((doc) => ({...doc.data(), id:doc.id})));
            setCategories(data2.docs.map((doc) => ({...doc.data(), id:doc.id})));
            setLoaded(true);
        };
        getPosts();
    }, [filter, length]);

    const changeFilter = ( async (param) => {
        setLoaded(false);
        setFilter(param);
    });

    const changeLength = ( async () => {
        setLength(length + 3);
    });
      

    return (
        <div className="section" id="blog">
            <h1>Amzsys Blog</h1>

            <div className='categories'>
                <button className={filter == 'popular' ? 'active': null} onClick={() => changeFilter("popular")}>Popular</button>
                <button className={filter == 'recent' ? 'active': null} onClick={() => changeFilter("recent")}>Latest</button>
                {
                    categories.map((category) => {
                        return (
                            <button className={filter == category.name ? 'active': null} onClick={() => changeFilter(category.name)}>{category.name}</button>
                        )
                    })
                }
            </div>

            { loaded == false ? <div className='loading'><img src={Loading} width="50" /></div> : 
                (
                    posts[0] === undefined ? <div className='loading'><h3>Nothing here</h3></div> :
                    <Link to={posts[0] == undefined ? '' : `/post/${posts[0].id}` } className="featured">
                        <div className="box img" style={{backgroundImage: 'url(' + (posts[0] == undefined ? '' : posts[0].image) + ')'}}></div>
                        <div className="box">
                            <h6>{ posts[0] == undefined ? '' : posts[0].date.toDate().toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"}) }</h6>
                            <h2>{ posts[0] == undefined ? '' : posts[0].title.length > 60 ? posts[0].title.substring(0, 60) + "..." : posts[0].title }</h2>
                            <p>{ posts[0] == undefined ? '' : posts[0].description.length > 200 ? posts[0].description.substring(0, 200) + "..." : posts[0].description}</p>
                        </div>
                    </Link>
                )
            }

            <div className="showcase">
                {
                    posts.map((post, index) => {
                        if (loaded != false)
                        {
                            return (
                                <Link to={`/post/${post.id}`} className="article">
                                    <div className="box img" style={{backgroundImage: 'url(' + post.image + ')'}}></div>
                                    <div className="box">
                                        <h6>{post.date == undefined ? '' : post.date.toDate().toLocaleDateString('en-us', {year:"numeric", month:"long", day:"numeric"}) }</h6>
                                        <h3>{post.title.length > 60 ? post.title.substring(0, 60) + "..." : post.title}</h3>
                                        <p>{post.description.length > 200 ? post.description.substring(0, 200) + "..." : post.description}</p>
                                    </div>
                                </Link>
                            )
                        }
                    })
                }
            </div>
            <div className='load-more'>
                <button onClick={changeLength}>Load More</button>
            </div>
        </div>
    )
}

export default Blog;