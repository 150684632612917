import React from 'react';
import './index.css';
import clent1 from '../../img/client1.png'
import clent2 from '../../img/client2.png'
import clent3 from '../../img/client3.png'
import clent4 from '../../img/client4.png'
import clent5 from '../../img/client5.png'
import clent6 from '../../img/client6.png'
import clent7 from '../../img/client7.png'
import clent8 from '../../img/client8.png'

function OdooCompany(){
    return (
        <div className="section" id="odoocompany">
            <h2>Trusted by top-notch companies</h2>
            <div className="logos">
                <img src={clent1} alt=""/>
                <img src={clent2} alt=""/>
                <img src={clent3} alt=""/>
                <img src={clent4} alt=""/>
                <img src={clent5} alt=""/>
                <img src={clent6} alt=""/>
                <img src={clent7} alt=""/>
                <img src={clent8} alt=""/>
            </div>
        </div>
    )
}

export default OdooCompany;