import React from 'react';
import './index.css';

function OdooCount(){
    return (
        <div id="odoocount">
            <div className="section">
                <div className="box">
                    <h2>173</h2>
                    <h3>Countries</h3>
                </div>
                <div className="box">
                    <h2>28K</h2>
                    <h3>Modules</h3>
                </div>
                <div className="box">
                    <h2>5M</h2>
                    <h3>Users</h3>
                </div>
                <div className="box">
                    <h2>1629</h2>
                    <h3>Partners</h3>
                </div>
            </div>
        </div>
    )
}

export default OdooCount;