import './App.css';
import NavBar from './components/navbar';
import HomeSec from './components/home';
import Speciality from './components/speciality';
import Odoo from './components/odoo';
import Services from './components/services';
import Contact from './components/contact';
import Testimonials from './components/testimonials';
import Footer from './components/footer';
import Top from './components/top';
import AboutMission from './components/about.mission';
import AboutVision from './components/about.vision';
import ScrollToTop from './components/scrollToTop';
import AboutSpeciality from './components/about.speciality';
import AboutWho from './components/about.who';
import Map from './components/map';
import ContactDetails from './components/contact.details';
import ContactMessage from './components/contact.message';
import OdooIntro from './components/odoo.intro';
import AboutOdoo from './components/odoo.about';
import OdooCount from './components/odoo.count';
import OdooCompany from './components/odoo.company';
import OdooServices from './components/odoo.services';
import ComingSoon from './components/comingsoon';
import WebDev from './components/webdev';
import GraphicDesign from './components/graphicdesign';
import WebHosting from './components/webhosting';
import Blog from './components/blog';
import BlogPost from './components/blog-post';
import $ from "jquery";
import { BrowserRouter as Router, Route } from "react-router-dom";

let navLinks = [
  {name: 'Home', link:'/'}, 
  {name: 'About Us', link:'/about'}, 
  {name: 'Odoo', link:[
      {name: 'About Odoo', link: '/about-odoo'}, 
      {name: 'Odoo Services', link: '/odoo-services'},
    ]
  }, 
  {name: 'Services', link:[
      {name: 'Web Development', link: '/web'}, 
      {name: 'Graphic Designing', link: '/graphic-design'},
      {name: 'Web & Email Hosting', link: '/web-hosting'}
    ]
  },
  {name: 'Blog', link: '/blog'}, 
  {name: 'Contact', link:'/contact'}
];

let testimonials = [

  {
    name: 'Abdul Gafoor',
    title: 'Chairman, Abhiman Crafts, Lulu Mall, India',
    quote: 'Amzsys is the most professional and honest team I met in my 32 year experience. I feel they can meet any of my requirements perfectly. They also have the credit for my business running successfully'
  },

  {
    name: 'Segrey Baguto',
    title: 'CEO, Stroyding LLC, Russia',
    quote: 'На сегодняшний день команда Amzsys единственная, которая отвечает высоким стандартам профессионализма в разработке, и самое главное интеграции Odoo'
  },

  {
    name: 'Yehuda Guzzi',
    title: 'CEO, Sodyna Ltd, Israel',
    quote: 'Very professional guys. I was able to detail my requirements directly with the programers and that was a geat plus. Fast and efficient and honest. I warmly crecomend them and wish them great success '
  },

  {
    name: 'Daniel Santiago',
    title: 'Owner, Finally Good IT, USA',
    quote: 'I was very pleased with the work and efforts provided. They really have the ground level of understanding of the systems I was working with. I will definitely hire them again!'
  }
]

function App() {
  return (
    <div className="App">

      <Router onEnter={() => window.scrollTo(0, 0)}>
        <ScrollToTop />
        <Route exact path="/" component={Home}/>
        <Route path="/about" component={About}/>
        <Route path="/contact" component={ContactPage} />
        <Route path="/about-odoo" component={AboutOdooPage} />
        <Route path="/odoo-services" component={OdooServicesPage} />
        <Route path="/apps" component={OdooAppsPage} />
        <Route path="/blog" component={BlogPage} />
        <Route exact path="/post/:id" component={BlogPostPage} />
        <Route path="/web" component={WebDevPage} />
        <Route path="/graphic-design" component={GraphicDesignPage} />
        <Route path="/web-hosting" component={WebHostingPage} />
      </Router>
    </div>
  );
}

function Home() {
    return (
    <>
      <NavBar items={ navLinks } fixed="false"/>
      <HomeSec />
      <Speciality />
      <Odoo />
      <Services />
      <Contact />
      <Testimonials items={ testimonials }/>
      <Footer />
    </>
  );
}

function About() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <Top title="About Us" nav={ [ {name: 'Home', link: '/'}, {name: 'About Us', link: '/about'}] }/>
      <AboutWho />
      <AboutMission />
      <AboutSpeciality />
      <AboutVision />
      <Footer />
    </>
  );
}

function ContactPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <Map />
      <ContactDetails />
      <ContactMessage />
      <Footer />
    </>
  );
}

function AboutOdooPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <OdooIntro />
      <AboutOdoo />
      <OdooCount />
      <OdooCompany />
      <Footer />
    </>
  );
}

function OdooServicesPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <OdooServices/>
      <Footer />
    </>
  );
}

function OdooAppsPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <ComingSoon />
      <Footer />
    </>
  );
}

function WebDevPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <WebDev />
      <Footer />
    </>
  );
}

function GraphicDesignPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <GraphicDesign />
      <Footer />
    </>
  );
}

function WebHostingPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <WebHosting />
      <Footer />
    </>
  );
}

function BlogPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <Blog />
      <Footer />
    </>
  );
}

function BlogPostPage() {
  return (
    <>
      <NavBar items={ navLinks } fixed="true"/>
      <BlogPost />
      <Footer />
    </>
  );
}

window.onscroll = function(){
  try{
    if ($(this).scrollTop() + 400 >= $('#speciality').position().top) {
      $('#speciality').addClass('inview');
    }
  
    if ($(this).scrollTop() + 400 >= $('#odoo').position().top) {
      $('#odoo').addClass('inview');
    }
  
    if ($(this).scrollTop() + 400 >= $('#services').position().top) {
      $('#services').addClass('inview');
    }
  }catch(e){
    //exception
  }

  if (window.pageYOffset > 30) {
    document.querySelector('.nav').classList.add('fixed');
  }else{
      document.querySelector('.nav').classList.remove('fixed');
  }
}

$(function(){

  $('*').hover(function(){
      $(this).data('hover',1); //store in that element that the mouse is over it
  },
  function(){
      $(this).data('hover',0); //store in that element that the mouse is no longer over it
  });

  
  $('.nav .right ul .sublink').click(function() {
    var alreadyClicked = $(this).hasClass('clicked');
    $('.nav .right ul .sublink').removeClass('clicked');
    if(alreadyClicked){
      $(this).removeClass('clicked');
    }else{
      $(this).addClass('clicked');
    }
  });


  window.isHovering = function (selector) {
      return $(selector).data('hover')?true:false; //check element for hover property
  }
});

export default App;
