import React from 'react';
import './index.css';

function Map(){
    return (
        <div id="map">
           <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.0183818662717!2d76.33507991474244!3d10.015339992840579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080cf45362d5b7%3A0xb4931144d471343d!2sAmzsys%20Technology%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1614517415276!5m2!1sen!2sin" allowFullScreen="" loading="lazy"></iframe> 
        </div>
    )
}

export default Map;