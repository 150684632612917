import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBooaDuiD_me1-v5YoG44E_0Fw5xQqJrdo",
  authDomain: "amzsys.firebaseapp.com",
  databaseURL: "https://amzsys-default-rtdb.firebaseio.com",
  projectId: "amzsys",
  storageBucket: "amzsys.appspot.com",
  messagingSenderId: "853410918610",
  appId: "1:853410918610:web:e6f49fb3af2a74e3d47d0b",
  measurementId: "G-VE4D8MMMSX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;